import React, { lazy, Suspense } from 'react';

const LazyStepLogin = lazy(() => import('./StepLogin'));

const StepLogin = (props: any & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyStepLogin {...props} />
  </Suspense>
);

export default StepLogin;
