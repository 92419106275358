import React, { lazy, Suspense } from 'react';

const LazyStep1 = lazy(() => import('./StepCompany'));

const Step1 = (props: any & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyStep1 {...props} />
  </Suspense>
);

export default Step1;
