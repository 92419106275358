import React from 'react';
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import classnames from "classnames";


interface fields {
    field: any,
    form: {
        touched: [],
        errors: [],
    },
    placeholder: string,
    name: string,
    checked?: boolean,
    floating?: boolean,
    label?: string,
    img?: string,
    hideLabel?: boolean,
    type: "email" | "text" | "checkbox" | "radio" | "switch" | "time",
    suffix?: any
}
/***
 *
 * presents a fully working bootstrap field, complete with feedback to the user if the input is not valid
 *
 * @param field
 * @param touched
 * @param errors
 * @param placeholder
 * @param name
 * @param type
 * @param checked
 * @param floating
 * @param label
 * @param img
 * @param hideLabel
 * @param suffix
 * @param props
 * @constructor
 **/
const FieldInput = ({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors },
                        placeholder,
                        name,
                        type,
                        checked,
                        floating,
                        label,
                        img,
                        hideLabel,
                        suffix,
                        ...props
                    }: fields) => {

    /**
     * are we rendering a checkbox?
     * we need to check because our labels and layout will shift if so
     */
    const wrapLabel = (type==='checkbox'||type==='radio'||type==='switch');
    const checkLabel  = (type==='checkbox'||type==='radio'||type==='switch');

    const switchLabel = (type==='switch');

    let floatLabel = (floating);
    floatLabel = checkLabel ? false : floatLabel; //overwride it if its a checklabel

    if(hideLabel)
    {
        placeholder = ''

    }



    /**
     * the field we are rendering
     *
     * if the field yet to be "touched" then we do not need to add an invalid/valid props
     *
     */
    const theField = (
        !touched[field.name] ? (

            <>

                    <Input {...field} type={type}
                           autoComplete="off"
                           placeholder={placeholder}
                           invalid={typeof errors[field.name] !== 'undefined'}
                    />
                    <span className={'input-suffix '}>
                        {suffix}
                    </span>

            </>
        ) : (
            <>
                    <Input {...field} type={type}
                           valid={!errors[field.name]}
                           invalid={typeof errors[field.name] !== 'undefined'}
                           autoComplete="off"
                           placeholder={placeholder}
                    />
                    <span className={'input-suffix '}>
                        {suffix}
                    </span>
                </>
        )
    )


    return(

        <>
            <FormGroup floating={floatLabel} check={checkLabel} switch={switchLabel} className={classnames((type==='radio'||type==='checkbox')&&'radio-input',(type==='radio'||type==='checkbox')&&field.checked&&'radio-input-checked',hideLabel&&'hidden-label')}>
                {/*if we dont need to wrap the label, the field goes outside the label*/}
                {(floatLabel && !checkLabel) && theField}
                <Label for={name} check={wrapLabel}>
                    {/*if we wrap the label, the field goes inside the label*/}
                    {
                        typeof img !== 'undefined' && checkLabel && (
                            <img src={img} alt={field.value}/>
                        )
                    }
                    {checkLabel && theField}
                    {(floatLabel || checkLabel) && placeholder}
                    {(!floatLabel && !checkLabel) && label}
                </Label>
                {(!floatLabel && !checkLabel) && theField}
                {/*feedback shown to the user if a validation error is detected*/}
                <FormFeedback>
                    {errors[field.name]}
                </FormFeedback>
                {/*<AnimatePresence exitBeforeEnter>
                                              {touched[field.name] &&
                                                  errors[field.name] && (
                                                      <motion.div
                                                          initial={initialStyle}
                                                          animate={enterStyle}
                                                          exit={exitStyle}
                                                          transition={{ duration: 0.15, type: 'tween' }}
                                                      >
                                                          <FormFeedback>
                                                              {errors[field.name]}
                                                          </FormFeedback>
                                                      </motion.div>
                                                  )}
                                          </AnimatePresence>*/}
            </FormGroup>
        </>
    )};

export default FieldInput;
