const scrollTo = (element: string) => {
  const elem = document.getElementById(element);
  if(typeof elem !== 'undefined' && elem !== null)
  {
    const y = elem.getBoundingClientRect().top + window.scrollY + -100;
    // console.log('scrolling')
    window.scrollTo({
      top: y,
      behavior: 'smooth'
    })
  }

}
export default scrollTo;