import { useEffect, useState } from 'react'

/**
 * custom hook over useState to clear a state after a certain period
 * @param interval
 */
function useTimeOutMessage(interval: number = 10000) {

    const [message, setMessage] = useState<string>('')

    useEffect(() => {
        if (message) {
            let timeout = setTimeout(() => setMessage(''), interval)
            return () => {
                clearTimeout(timeout)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message])

    //must be exported as const otherwise typescript throws type errors
    return [message, setMessage] as const
}

export default useTimeOutMessage