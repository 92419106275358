import React from 'react';
import {IoMdCloseCircle,IoMdInformationCircle,IoIosCheckmarkCircleOutline,IoIosInformationCircleOutline} from "react-icons/io";
import {AlertInterface} from '../../../interfaces/Alert.model'
import classnames from "classnames";

/**
 * a custom component to create bootstrap style alerts but with icons and some custom class names
 * @param props
 * @constructor
 */
const Alert = (props:AlertInterface) => {

    /**
     * determine which icon to show based on the prop type
     */
    let theIcon:JSX.Element;
    switch(props.type) {
        case "info":
            theIcon = <IoMdInformationCircle size={'1.5rem'}/>
            break;
        case "success":
            theIcon = <IoIosCheckmarkCircleOutline size={'1.5rem'}/>
            break;
        case "warning":
            theIcon = <IoIosInformationCircleOutline size={'1.5rem'}/>
            break;
        case "danger":
            theIcon = <IoMdCloseCircle size={'1.5rem'}/>
            break;
    }

    return(
        <>
            <div className={classnames(`alert alert-${props.type} fade show d-flex`, props.title && 'alert-with-title', props.className)} role={'alert'}>
                <div className={classnames('d-flex justify-content-start')}>
                    {props.showIcon && (
                        <span>
                            {theIcon}
                        </span>
                    )}
                    <div className={classnames(props.showIcon && 'ms-2')}>
                        {props.title && (
                            <div className={classnames('mb-1 alert-title')}>
                                {props.title}
                            </div>
                        )}
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    )
};

export default Alert;
