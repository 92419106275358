import React from 'react';
import styles from './Partners.module.scss';
import {Col, Container, Row} from "reactstrap";
import classnames from "classnames";
import club1 from "../../assets/club-logos/ccc-club.svg";
import club2 from "../../assets/club-logos/cmc-logo.svg";
import club3 from "../../assets/club-logos/mcc-logo.png";
import club4 from "../../assets/club-logos/gcc-logo.png";


export interface PartnersInterface {

}

const Partners = (props: PartnersInterface) =>
{

    const images = [
        {
            src: club1
        },
        {
            src: club2
        },{
            src: club3
        },{
            src: club4
        }
    ]

    return(
        <>
            <section className={styles.Partners}>

                <Container>
                    <h3>Designed for owners of CLs, CSs and other membership campsites</h3>

                    <Row>
                        {images.map((item) => {
                            return(
                                <Col md={'3'} className={'justify-content-center'}>
                                    <img src={item.src} alt={''} className={classnames(styles.PartnerLogos,'img-fluid')}/>
                                </Col>
                            )
                        })}
                    </Row>
                    <Row>
                        <Col>
                            <p className={'text-center mt-3 fw-bolder'}>
                                Trusted by the largest Campsite and Motorhome Clubs in the United Kingdom
                            </p>
                        </Col>
                    </Row>
                </Container>



            </section>
        </>
    )
}

export default Partners;
