import React, {useEffect, useState} from 'react';

import {Helmet} from "react-helmet";
import SignUpForm from "../../components/SignUpForm/SignUpForm";
import {Container} from "reactstrap";
import Hero from '../../components/Hero/Hero.lazy';
import Nav from "../../components/Nav/Nav.lazy";
import CTABanner from "../../components/ui/CTA/CTABanner";
import Footer from "../../components/Footer/Footer.lazy";


const initialState = {

}


const Fragment = React.Fragment;

interface pageDataInterface {
    ctaBanner: {
        text: string,
        heading: JSX.Element
    }
}

export default function LandingPage() {

    const [pageData, setPageData] = useState<pageDataInterface|undefined>(undefined);


    useEffect(() => {
        if(typeof pageData === 'undefined')
        {
            setPageData(
                {
                    ctaBanner: {
                        text: 'Signing up for your 90 day free trial takes less than 5 minutes!',
                        heading: <>Ready to join <strong>hundreds</strong> of campsites across the UK already using Anytime?</>
                    }
                })

        }
    }, [pageData])

        return(
            <>
                <Helmet>
                    <title>{'Anytime Booking - The best booking system for Campsites'}</title>
                    <meta name="description" content={' '}/>
                </Helmet>
                {typeof pageData !== 'undefined' && (
                    <>
                        <main>
                            <Nav/>
                            <Hero />
                            {/*<CTABanner text={'Signing up for your 30 day free trial takes less than 5 minutes!'} heading={<Fragment>Ready to join <strong>hundreds</strong> of campsites across the UK already using Anytime?</Fragment>}/>*/}



                            <CTABanner data={pageData?.ctaBanner}/>


                            <section className={'pb-5'}>
                                <Container>
                                        <SignUpForm/>
                                </Container>
                            </section>
                        </main>
                        <Footer/>
                    </>
                )}
            </>
        )

}
