import React from 'react';
import styles from './Card.module.scss';
import classnames from "classnames";

export interface CardInterface {
    children: React.ReactNode
}

const Card = (props: CardInterface) => {



    return(

        <>
            <div className={classnames(styles.Card,'shadow-sm px-3 py-3 mt-5 bg-body rounded')}>
                {props.children}
            </div>
        </>
    )
};

export default Card;
