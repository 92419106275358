import React, {useEffect, useState} from 'react';
import styles from './SignUpWizard.module.scss';
import {Col, Row} from "reactstrap";
import classnames from 'classnames';
import Header from "./Header/Header.lazy";
import {StepItemsInterface} from "../../interfaces/Steps.model";
import StepCompany from "./StepCompany/StepCompany.lazy";
import StepUnits from "./StepUnits/StepUnits.lazy";
import StepRules from "./StepRules/StepRules.lazy";
import StepLogin from "./StepLogin/StepLogin.lazy";
import {useNavigate} from "react-router-dom";
import {User} from "../../interfaces/user.model";

const SignUpWizard = (props: any) =>
{

    /**
     * active step holds the step the user has made the most progress up to
     */
    const [activeStep, setActiveStep] = useState<number>(props.step);
    //visible step shows the current visible step
    const [visibleStep, setVisibleStep] = useState<number>(activeStep);
    // for example, a user may have got as far as step 3, but is viewing step 1 to make amendments
    // in this instance activeStep = 3, visibleStep = 1.
    const navigate = useNavigate();
    const changeStep = (direction: string | undefined) => {

        if(typeof direction === 'undefined')
        {
            direction = 'forward'
        }

        let goToStep: number;

        //the next step cannot be more than the signup length
        //if it is, just set it to the final step
        //otherwise increment by one.
        if(direction==='forward')
        {
            goToStep = (activeStep+1>signUpSteps.length?signUpSteps.length:activeStep+1);
        } else {
            goToStep = (activeStep-1<0?0:activeStep-1);
        }
        // console.log(activeStep,goToStep,direction)
        // let history = useHistory();
        // navigate(`/signup/${props.user.token}/${nextStep}`)
        setActiveStep(goToStep)
        setVisibleStep(goToStep)

        //added due to complaints from users in testing that on small screens
        //the form wasn't reverting to top of page
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

    }

    const setUser = (data: User) => {
        props.setUser(data)

    }

    const setStep = (index: number) => {
        setVisibleStep(index)
    }

    //always run this on load
    useEffect(() => {
        if(typeof props.step !== 'undefined')
        {
            setVisibleStep(Number(props.step))
        }
    }, [props.step])

    /**
     * what are the names and order of the steps?
     */
    let signUpSteps: StepItemsInterface= [
        {
            name:'Your Business',
            component: <StepCompany user={props.user} changeStep={changeStep} setUser={setUser}/>
        },
        {
            name:'Your Campsite',
            component: <StepRules user={props.user} changeStep={changeStep} setUser={setUser}/>
        },
    ]

    let ruleData = props.user?.data?.rules;

    if(typeof ruleData === 'undefined' || (ruleData.pitchesQtyGrass==="0" && ruleData.pitchesQtyHardStanding==="0"))
    {
        signUpSteps.push(

            {
                name:'Pitches & Pricing',
                component: <StepUnits user={props.user} changeStep={changeStep} setUser={setUser}/>
            },
        )
    } else {
        if(ruleData.pitchesQtyHardStanding>0)
        {
            signUpSteps.push(
                {
                    name:'Hardstanding Pricing',
                    component: <StepUnits user={props.user} changeStep={changeStep} setUser={setUser} type={'Hardstanding'}/>
                },
            )
        }

        if(ruleData.pitchesQtyGrass>0)
        {
            signUpSteps.push(

                {
                    name:'Grass Pitch Pricing',
                    component: <StepUnits user={props.user} changeStep={changeStep} setUser={setUser} type={'Grass'}/>
                },
            )
        }
    }

    signUpSteps.push(
        {
            name:'Login Details', component: <StepLogin user={props.user} changeStep={changeStep} setUser={setUser}/>
        },
    )


    console.log(signUpSteps,signUpSteps[visibleStep-1])


    return(
        <>
            <div className={classnames(styles.SignUpWizard, 'mt-5 mb-5  d-flex align-items-center justify-content-center')} data-testid="SignUpForm">
                <Col>
                    <Row>
                        {/* the steps header at the top of the form*/}
                        <Header items={signUpSteps} active={visibleStep} clickableSteps={activeStep} setStep={setStep} setActiveStep={setActiveStep}/>
                    </Row>
                    <Row>
                        {/* render the current visible step's component */}
                        {signUpSteps[visibleStep-1].component}
                    </Row>
                </Col>
            </div>
        </>
    )

}

export default SignUpWizard;
