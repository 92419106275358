import React from 'react';
import styles from './CTA.module.scss';
import {Button, Col, Container, Row} from "reactstrap";
import img from '../../../assets/ctaclouds.webp'
import {IoChevronForwardCircleSharp} from "react-icons/io5";
import {RiMoneyPoundCircleLine} from "react-icons/ri";
import {Slide} from "react-awesome-reveal";
import {MdMarkEmailRead} from "react-icons/md";
import {HiOutlineCalendarDays} from "react-icons/hi2";
import Partners from "../../Partners/Partners";
import stripe from "../../../assets/payment-logos/stripe-logo.svg";
import creditcard from "../../../assets/credit-card.webp";
import clbooking from "../../../assets/club-logos/cl-booking.png";


import scrollTo from "../../../hooks/scrollTo";
import classnames from "classnames";

const CTABanner = (props: any) => {

    const {heading, text} = props.data;


    const Fragment = React.Fragment;


    return(

        <>
            <Container fluid={true} className={styles.banner}>
                <Container style={{overflow:'hidden'}}>
                    <Row>
                        <Col md={4} className={'text-center'}>
                            <Slide  direction={'up'}>
                                <div>
                                    <HiOutlineCalendarDays size={'5rem'} className={styles.bannerIcon}/>
                                </div>
                                <h4>Show pricing and availability online!</h4>
                                <p>Save time and eliminate the hassle of phone calls and emails by allowing your guests to see live availability and prices online so they can simply book themselves.</p>
                            </Slide>
                        </Col>
                        <Col md={4} className={'text-center'}>
                            <Slide direction={'up'}>
                                <div>
                                    <MdMarkEmailRead size={'5rem'} className={styles.bannerIcon}/>
                                </div>
                                <h4>Never send an email manually again!</h4>
                                <p>Automatically email booking confirmations, balance reminders, arrival instructions and more! Write your emails once, turn them on and your guest communications will flow seamlessly!</p>
                            </Slide>
                        </Col>
                        <Col md={4} className={'text-center'}>
                            <Slide direction={'up'}>
                                <div>
                                    <RiMoneyPoundCircleLine size={'5rem'} className={styles.bannerIcon}/>
                                </div>
                                <h4>Automate all your customer payments!</h4>
                                <p>Collect instant payments from your club members when they make their booking online. You can even ask for a deposit first and the balance before they arrive - everything is automated.</p>
                            </Slide>
                        </Col>


                        {/*<Col md={3} className={'text-center'}>
                            <Slide direction={'right'} delay={350}>
                                <div>
                                     eslint-disable-next-line react/jsx-no-undef
                                    <HiCodeBracket size={'5rem'}/>
                                </div>
                                <h4>No website needed!</h4>
                                <p>Whilst we're happy to work with you to build out your dream website as a well, our system works without any complicated websites attached! You can even take bookings <strong>Direct from Facebook with our 'Book Now' buttons</strong></p>
                            </Slide>
                        </Col>*/}
                    </Row>
                </Container>
            </Container>
            <Partners/>
            <Container>
                <section className={styles.Cta}>
                    <img src={img} alt={'clouds'}/>
                    <h4>{heading}</h4>
                    <p><Fragment>{text}</Fragment></p>
                    <Button size={'lg'} color={'primary'} onClick={() => {scrollTo('signupform')}}>Get Started For Free <IoChevronForwardCircleSharp size={'1.8rem'} className={'mb-1'}/></Button>
                </section>
            </Container>

            <Container>
                <section className={styles.ServiceBlock}>
                    <Row>
                        <Col lg={5} className={'text-center my-3'}>
                            <img src={clbooking} alt={'CL Booking'} className={classnames(styles.img,'img-fluid img')}/>
                        </Col>
                        <Col lg={7} className={'text-center my-3'}>
                            <h2>Easy Integration with CL Booking</h2>
                            <p>CL Booking is the website and mobile app that actively promotes your site to thousands of visitors looking to book. CL Booking works directly with Anytime Booking to synchronise calendars.</p>
                        </Col>
                    </Row>
                </section>
            </Container>

            <Container>
                <section className={styles.ServiceBlock}>
                <Row>
                    <Col lg={6} className={'text-center mt-5 mb-5'}>
                        <h2>Automated Payments</h2>
                        <p>We've partnered with Stripe, one of the largest and easiest to use online Payment Providers available. This allows us to fully automate your customers payments, from the moment they book with a deposit, to their final balance before they arrive.</p>
                        <Button size={'lg'} color={'primary'} onClick={() => {scrollTo('signupform')}}>Get Started For Free <IoChevronForwardCircleSharp size={'1.8rem'} className={'mb-1'}/></Button>
                        <Col className={classnames('d-flex justify-content-start',styles.logos)}>
                            <div>
                                <img src={stripe} alt={'Accept payments with stripe'}/>
                            </div>
                        </Col>
                    </Col>
                    <Col lg={6}>
                        <img src={creditcard} alt={'Accept payments with stripe'} className={classnames(styles.img,'img-fluid img')}/>
                    </Col>
                </Row>
                </section>
            </Container>

        </>
    )
};

export default CTABanner;
