import React, { lazy, Suspense } from 'react';
import {StepsInterface} from "../../../interfaces/Steps.model";

const LazySteps = lazy(() => import('./Header'));

const Steps = (props: StepsInterface & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazySteps {...props} />
  </Suspense>
);

export default Steps;
