import React, { lazy, Suspense } from 'react';

const LazyStepRules = lazy(() => import('./StepRules'));

const StepRules = (props: any & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyStepRules {...props} />
  </Suspense>
);

export default StepRules;
