import React from 'react';
import 'rsuite/dist/rsuite.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Wizard from "./pages/SignUpWizard/Wizard";
import LandingPage from "./pages/LandingPage/LandingPage";
console.log(process.env.NODE_ENV)
function App() {
    return (

        <BrowserRouter>
            <Routes>
                <Route index path={'/'} element={<LandingPage/>}/>
                <Route path={'/signup/'}>
                    <Route path={':token/:step'} element={<Wizard/>}/>
                    <Route path={':token'} element={<Wizard/>}/>

                </Route>

            </Routes>
        </BrowserRouter>

    );
}

export default App;
