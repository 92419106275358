import React, { lazy, Suspense } from 'react';

const LazyStep2 = lazy(() => import('./StepUnits'));

const Step2 = (props: any & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyStep2 {...props} />
  </Suspense>
);

export default Step2;
