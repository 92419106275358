import React, {Dispatch, lazy, SetStateAction, Suspense} from 'react';
import {BaseUserInfo} from "../../interfaces/user.model";

const LazySignUpSuccess = lazy(() => import('./SignUpSuccess'));

const SignUpSuccess = (props: {user:BaseUserInfo, setSuccess: Dispatch<SetStateAction<boolean>>}) => (
  <Suspense fallback={null}>
    <LazySignUpSuccess user={props.user} setSuccess={props.setSuccess}/>
  </Suspense>
);

export default SignUpSuccess;
