import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Helmet} from "react-helmet";
import SignUpWizard from "../../components/SignUpWizard/SignUpWizard";
import {apiPrefix} from '../../configs/app.config';
import axios from "axios";
import {User} from "../../interfaces/user.model";
import {Col, Container, Row} from "reactstrap";
import Card from "../../components/ui/Card/Card";
import Alert from "../../components/ui/Alert/Alert";


export default function Wizard() {

    //params will hold the token passed to the page via the url
    let params = useParams();
    //user/setUser to hold the user state
    const [user, setUser] = useState<User>();
    //error/setError to hold the error state if we can't find a matching user based off their token
    const [error, setError] = useState<string|null>(null);

    const [step, setStep] = useState<number>();

    //always run this on load
    useEffect(() => {
        //if we haven't set a user
        if(typeof user === 'undefined')
        {

            //TODO handle initial loading state to show we're fetching the account and generic AXIOS errors

            //axios request to retrieve the user based on the token
            //this is a Typed axios request and expects the User to come back as defined in our interface
            axios.get<User>(`${apiPrefix}/auth/${params.token}`)
                .then(r => {
                    //set the user if we had a successful response
                    setUser(r.data)
                }).catch(err => {
                    //error out otherwise
                if(typeof err.response?.data ==='undefined')
                {
                    setError('Sorry there was a problem finding your account with the link provided.')
                } else {
                    setError(err.response.data)
                }
            })
        }

        if(typeof params.step !== 'undefined')
        {
            setStep(Number(params.step))
        } else {
            setStep(1)

        }
    }, [params, user])

    return(
        <>
            {(typeof user !== 'undefined') &&
                <>
                <Helmet>
                    <title>{`Hi ${user.firstName} - Finish Signing up`}</title>
                </Helmet>
                  <Container className={'vh-100'}>
                    <Row className={'vh-100'}>
                      <Col>
                        <SignUpWizard user={user} step={step} setUser={setUser}/>
                      </Col>
                    </Row>
                  </Container>
                </>
            }
            {(error !== null) &&
                <>
                  <Helmet>
                    <title>{`Oops!`}</title>
                  </Helmet>
                  <Container className={'vh-100'}>
                    <Row className={'vh-100'}>
                      <Col>
                    <Card>
                      <Alert type={'warning'} showIcon={true} title={'Oops!'}>
                          {error==='Link Expired. Signup Complete' ?
                              (`It appears you have already successfully signed up for Anytime Booking LITE. Please check your emails for a link to your account to sign in to Anytime Booking.`)
                              :
                              ('The link you are visiting is no longer valid. Please check the link you recieved in your Verification Email, if you believe this is an error please contact us.')
                          }
                      </Alert>
                    </Card>
                      </Col>
                    </Row>
                  </Container>
                </>
            }
        </>
    )
}
