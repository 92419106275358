import React, {useState} from 'react';
import {Field, Form, Formik, FormikValues} from 'formik';
import * as Yup from 'yup'
import styles from './SignUpForm.module.scss';
import {Button, Col, Row, Spinner} from "reactstrap";
import classnames from 'classnames';
import FieldInput from "../ui/FieldInput/FieldInput";
import Alert from '../ui/Alert/Alert'
import {BaseUserInfo} from "../../interfaces/user.model";
import axios from "axios";
import {apiPrefix} from "../../configs/app.config";
import useTimeOutMessage from "../../hooks/useTimeOutMessage";
import {IoChevronForwardCircleSharp} from "react-icons/io5";
import SignUpSuccess from "../SignUpSuccess/SignUpSuccess.lazy";
import {Slide} from "react-awesome-reveal";

/**
 * set the initial form values
 */
const initialValues: BaseUserInfo = {
    firstName: '',
    lastName: '',
    email: '',
    consent: false
}


/**
 * form validation
 */
const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Your first name is required'),
    lastName: Yup.string().required('Your surname is required'),
    email: Yup.string().email('Invalid email').required('Email Required'),
})



const SignUpForm = () =>
{

    //custom hook for timed out state management (this will hide the message after a few seconds)
    const [error, setError] = useTimeOutMessage();
    //states for our axios requests
    const [success, setSuccess] = useState<boolean>(false);
    //user states to send off to the api
    const [user, setUser] = useState<BaseUserInfo>(initialValues);

    /**
     * handle the form submit
     * @param values (values of all fields)
     * @param setSubmitting (whether the form is actively submitting)
     */
    const onFormSubmit = (values: FormikValues, setSubmitting: (isSubmitting: boolean) => void) => {
        //deconstruct the user from the form values submitted
        const {firstName, lastName, email, consent} = values;
        setUser({
            firstName: firstName,
            lastName: lastName,
            email: email,
            consent: consent
        })

        //axios post to our api endpoint to make the user
        axios.post(`${apiPrefix}/auth`, {
            firstName: firstName,
            lastName: lastName,
            email: email,
            companyEmail: email,
            data: {
                company: {
                    companyEmail: email
                }
            }
        })
            .then(() => {
                setSuccess(true)
            })
            .catch(err => {
                //check for generic axios error
                if(typeof err.response?.data === 'undefined')
                {
                    setError('Sorry there was a problem creating your account.')
                } else {
                    //error handled via our api
                    setError(err.response.data.error)
                }
        })
            .then(() => {
            setSubmitting(false)
        })
    }

    //shows on the button when submitting
    const submittingMsg = (
        <>
            <Spinner size="sm">
                Making you an account...
            </Spinner>
            {` Making you an account...`}
        </>
    )

    //default message to show on the button
    const defaultMsg = (
        <>
            Continue <IoChevronForwardCircleSharp size={'1.25rem'}/>
        </>
    )


    return(
        <>
            <div className={classnames(styles.SignUpForm, 'shadow-sm p-5 mt-5 mb-5 rounded d-flex align-items-center justify-content-center col-12')} data-testid="SignUpForm">
                <div className={classnames(styles.Logo, 'd-flex shadow-sm justify-content-center')}>

                </div>
                {success ? (
                    <>
                        <SignUpSuccess user={user} setSuccess={setSuccess}/>
                    </>
                ) : (
                    <>

                        <Row>
                            <Col       sm={{
                                offset: 2,
                                size: 8
                            }}>
                                <Formik
                                    initialValues={initialValues}
                                    enableReinitialize
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(true)
                                        //artificially slow the form down to show we're doing something
                                        setTimeout(() => {
                                            onFormSubmit(values, setSubmitting)
                                        }, 750)
                                    }}

                                >
                                    {({isSubmitting}) => {
                                        return (
                                            <Form id="signupform">
                                                <h5>Try Anytime FREE for 90 days!</h5>
                                                <p><small>Then for just £24 per month*, there's no contracts and no obligations, just a better booking system.</small></p>
                                                <p>Fill in the form below to gain access.</p>
                                                {error &&
                                                    <Alert type="danger" showIcon={true}>
                                                        {error}
                                                    </Alert>
                                                }
                                                <Row>
                                                    <Col md={6}>
                                                        <Field type="text" name="firstName" placeholder="Your first name" floating component={FieldInput}/>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Field type="text" name="lastName" placeholder="Your surname" floating component={FieldInput}/>
                                                    </Col>
                                                </Row>
                                                <Col md={12}>
                                                    <Field type="email" name="email" placeholder="Your email address" floating component={FieldInput}/>
                                                </Col>
                                                {/*<Col md={12}>
                                                <Field type="checkbox" name="consent" placeholder="Opt in to marketing" component={FieldInput}/>
                                            </Col>*/}
                                                <Button color="primary" disabled={isSubmitting} type="submit" block
                                                        className={'mt-3'}
                                                >
                                                    {isSubmitting ? submittingMsg: defaultMsg}
                                                </Button>
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Col>

                            <Col       sm={{
                                offset: 2,
                                size: 8
                            }} className="align-middle">

                                <ul className={styles.SignUpList}>
                                    <Slide direction={'right'} cascade triggerOnce={true}>
                                        <h4>What happens next?</h4>
                                        <li>
                                            We'll send you one simple email and you can start setting up your account!
                                        </li>
                                        <li>
                                            You'll have full access to all features for <strong>FREE</strong> for 90 days!
                                        </li>
                                        <li>
                                            You'll be up and running within a few minutes and be able to take bookings.
                                        </li>
                                        <li>
                                            After 90 days, you'll have the option to continue using Anytime Booking for £24 per month*, with no long term contracts to sign.
                                        </li>
                                    </Slide>
                                </ul>
                                <small className={styles.smallPrint}>
                                   * £24 per month is inclusive of VAT and includes access to our Anytime Booking LITE system, which is perfect for Certified Locations and Campsites.
                                </small>

                            </Col>
                        </Row>



                    </>

                )}
            </div>
            <div className={'clearfix'}></div>
        </>
    )

}

export default SignUpForm;
